import React, { useState } from "react";
import { getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import loadable from "@loadable/component";

const Image = loadable(() => import("./image.js"));
const GalleryModal = loadable(() => import("./gallery-modal.js"));
const GalleryCarousel = loadable(() => import("./gallery-carousel.js"));
const NavGrid = loadable(() => import("./navgrid.js"));

import "../../styles/blocks/gallery.scss";

const Gallery = (props) => {
  const query = graphql`
  query GalleryImages {
    site {
      siteMetadata {
        navGridAsBackgroundImages
        navGridTitleForButton
      }
    }
    allWpMediaItem {
      nodes {
        altText
        caption
        sourceUrl 
        databaseId
        localFile {
          childImageSharp {
            smallmore: gatsbyImageData(
              width:470,
              height: 384, 
              transformOptions: {cropFocus: CENTER}
            )
          }
        }
      }
    }
  }`;
  const allImages = useStaticQuery(query);
  const [isOpen, setIsOpen] = useState(false);
  const [activeImage, setActiveImage] = useState(0);
  const openModal = (index) => {
    setIsOpen(true);
    setActiveImage(index);
  };
  const getImgUrl = (id) => {
    const targetImage = allImages.allWpMediaItem.nodes.find(e => {
      return e.databaseId.toString() === id.toString();
    });
    if (targetImage) {
      const images = getImage(targetImage.localFile.childImageSharp.smallmore).images.fallback;
      return images.src;
    }
    return id;
  };
  if (props.attributes.variation === "navgrid") {
    return (<NavGrid
      {...props}
      navGridAsBackgroundImages={ allImages.site.siteMetadata.navGridAsBackgroundImages }
      navGridTitleForButton={ allImages.site.siteMetadata.navGridTitleForButton }
      innerBlocks={
        props.innerBlocks.map(e => {
          e.url = getImgUrl(e.attributes.id);
          return e;
        })
      }
    />);
  }

  //  Carousel
  if (props.attributes.variation === "carousel") {
    let className = `wp-block-carousel ${ props.attributes.className || "" }`;
    if(props.attributes.align && props.attributes.align.length) {
      className += " align"+props.attributes.align;
    }
    className += (props.attributes.carouselNavigation) ? ` ${ props.attributes.carouselNavigation }` : " boutons";
    return (
      <GalleryCarousel
        style={ props.style }
        id={ props.anchor } 
        className={ className } 
        openingModal={ true }
        fixedImageNumber={ false }
        isOpen={ isOpen }
        setIsOpen={ setIsOpen }
        typeButton={ props.attributes.carouselNavigation || "boutons" }
        activeImage={ activeImage }
        setActiveImage={ setActiveImage }
        content={ props.innerBlocks }
        type={ props.type }
      />
    );
  }

  // Gallery
  let className = `wp-block-gallery ${ props.attributes.className || ""}`;
  if (props.attributes.align && props.attributes.align.length) {
    className += " align"+props.attributes.align;
  } 
  return (
    <>
      <div id={ props.anchor } className={ className } style={ props.style }>
        { props.innerBlocks.map((image,i) => {
          return(
            <Image
              attributes={ image.attributes }
              size="small"
              key={ i } 
              onClick={ () => openModal(i) }
            />
          );
        })}
        {
          className.includes("has-modal")
          ? <GalleryModal
              isOpen={ isOpen }
              setIsOpen={ setIsOpen }
              activeImage={ activeImage }
              setActiveImage={ setActiveImage } 
              content={ props.innerBlocks }
            />
          : <></>
        }
      </div>
    </>
  ); 
};

export default Gallery;
